import styled from 'styled-components'
import media from '../../common/utils/media'
import PolicyLinks from '../molecules/PolicyLinks'
import CorporateLinks from '../molecules/CorporateLinks'
import SocialLinks from '../molecules/SocialLinks'

const Footer = () => (
    <StyledFooter>
      <CorporateLinks />
      <SocialLinks />
      <PolicyLinks />
      <footer className="footer">©2022 AlpacaTech Co. Ltd.</footer>
    </StyledFooter>
)

export default Footer

const StyledFooter = styled.div`
  background-color: #15242f;
  display: grid;
  grid-auto-flow: column;
  grid-template: 
    "a c"
    "b d" / 2fr 1fr;
  padding: 100px 14vw;
  text-align: left;
  margin-top: auto;

  footer {
    margin-top: auto;
    font-size: 0.8em;
    color: #8a979e;
    
    ${media.tablet`
      padding-top: 30px;
    `}
  }

  ${media.tablet`
    display: block;
    padding: 50px 100px;
  `}

  ${media.sp`
    padding: 50px;
  `}
`
