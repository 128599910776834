import styled from 'styled-components'
import { ROUTE_JOB } from '../../common/utils/constants'
import media from '../../common/utils/media'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'
import ApplyButton from '../atoms/ApplyButton'

const HiringPage = () => {
  const history = useHistory()
  const goToJobPage = (job: string) => {
    history.push(ROUTE_JOB.replace(':job', job).replace(':lang', 'en'))
  }

  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(true)

  const getData = () => {
    // prevent browser from caching the file by appending random parameter.
    // source: https://stackoverflow.com/a/15041641
    fetch(`../../jobs/jobs.json?nocache=${(new Date()).getTime()}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        setJobs(json['jobs'])
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Header />
      <StyledHiring>
        {!loading && (
          <>
            <div className="subtitle-container">
              <h2>
                {jobs.length > 0
                  ? 'We are hiring.'
                  : 'Current Job Opportunities'}
              </h2>
            </div>
            <br />
            {jobs.length > 0 ? (
              <p>
                Work from anywhere, flexible hours, in an extremely inclusive
                environment.
                <br />
                We don’t ask you to verify that you are not a computer.
                <br />
                <br />
                <ApplyButton />
              </p>
            ) : (
              <p>
                Unfortunately we do not have any open positions right now.
                <br />
                <br />
                Please check this page again for our upcoming positions.
              </p>
            )}
            <div className="jobs">
              {jobs.map((job: any) => (
                <div
                  key={job.title}
                  onClick={() => {
                    goToJobPage(job.source)
                  }}
                >
                  {job.title}
                </div>
              ))}
            </div>
          </>
        )}
      </StyledHiring>
      <Footer />
    </>
  )
}

export default HiringPage

export const StyledHiring = styled.div`
  padding: 80px 14vw;

  ${media.tablet`
    padding: 50px 100px;
  `}

  ${media.sp`
    padding: 50px;
  `}

  div.subtitle-container {
    width: fit-content;
  }
  h2 {
    width: 100%;
    text-align: left;
    margin-bottom: 0;
    font-family: montserrat;
    animation: 
    typing 2.5s steps(45, end),
    blink-caret .75s step-end infinite;
    overflow: hidden;
    border-right: .15em solid #ff3333; /* The typwriter cursor */
    white-space: nowrap; Keeps the content on a single line
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0.05em; //Adjust as needed

    ${media.sp`
      margin: 0;
      letter-spacing: 0.01em;
    `}
  }

  p {
    text-align: left;

    ${media.pcNormal`
      width: 50%;
    `}

    ${media.tablet`
      width: 100%;
    `}
  }

  div.jobs {
    display: grid;

    ${media.pcNormal`
    grid-template-columns: repeat(3, 1fr);
    `}

    ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
    `}

    ${media.chromeSp`
    grid-template-columns: repeat(1, 1fr);
    `}

    grid-auto-rows: auto;
    grid-gap: 1rem;
    margin-top: 80px;

    div {
      border: 1px solid white;
      border-radius: 20px;
      padding: 100px 30px;
      font-size: 1.2em;
      font-weight: bold;
      color: white;
      cursor: pointer;
      position: relative;
    }

    div:before,
    div:after {
      border-right: 2.5px solid lightgrey;
      content: '';
      display: block;
      height: 10px;
      margin-top: -7px;
      position: absolute;
      transform: rotate(135deg);
      right: 20px;
      top: 50%;
      width: 0;
    }

    div:after {
      margin-top: -1px;
      transform: rotate(45deg);
    }
  }

  
`
