import ICO_CROSS from '../../assets/images/icon-cross.svg'
import styled from 'styled-components'
import { ROUTE_ABOUT_US, ROUTE_CONTACT_US, ROUTE_HIRING, ROUTE_NEWS_RELEASE } from '../../common/utils/constants'

type MobilePopupProps = {
  isMobilePopupShown: boolean
  onCloseBtnClicked: Function
}

const MobilePopup = ({
  isMobilePopupShown,
  onCloseBtnClicked,
}: MobilePopupProps) => {
  return (
    <StyledMobilePopup
      role="navigation"
      className={`mobile-popup ${isMobilePopupShown ? '-active' : ''}`}
    >
      <div className="mobile-popup-container">
        <div className="mobile-popup-header">
          <div>
            <a href="/" className="title">
              AlpacaTech
            </a>
          </div>
          <div
            className="btn-close"
            onClick={() => {
              onCloseBtnClicked()
            }}
          >
            <img
              src={ICO_CROSS}
              alt="close-mobile-popup"
              title="close-mobile-popup"
            />
          </div>
        </div>
        <div className="mobile-links">
          <a
            href={ROUTE_ABOUT_US}
            className={
              window.location.pathname.includes(ROUTE_ABOUT_US) ? 'active' : ''
            }
          >
            About Us
          </a>
          <a
            href={ROUTE_NEWS_RELEASE}
            className={
              window.location.pathname.includes(ROUTE_NEWS_RELEASE) ? 'active' : ''
            }
          >
            News Release
          </a>
          <a
            href={ROUTE_HIRING}
            className={
              window.location.pathname.includes(ROUTE_HIRING) ? 'active' : ''
            }
          >
            Jobs
          </a>
          <a
            href={ROUTE_CONTACT_US}
            className={
              window.location.pathname.includes(ROUTE_CONTACT_US) ? 'active' : ''
            }
          >
            Contact Us
          </a>
        </div>
      </div>
    </StyledMobilePopup>
  )
}

export default MobilePopup

const StyledMobilePopup = styled.nav`
  & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 3vh 14vw;
    z-index: 1;
    visibility: hidden;
    transition: 0.3s;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;

    &:before {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      width: 0;
      height: 100%;
      background: #000;
      transition: 0.5s 0.5s ease-in-out;
    }
  }

  &.-active {
    visibility: visible;
    transition: all 0.5s ease-in-out;
    &:before {
      width: 100%;
      transition: all 0.5s ease-in-out;
    }

    & .mobile-popup-container {
      opacity: 1;
      transform: translateX(0);
      transition: 0.5s 0.5s ease-in-out;
    }
  }

  .mobile-popup-container {
    position: relative;
    opacity: 0;
    transform: translateX(30px);
    transition: all 0.5s ease-in-out;
  }

  .mobile-popup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;

    a.title {
      text-decoration: none;
      color: #ff3333;
      font-size: 1.5em;
      font-weight: 800;
    }
  }

  .btn-close {
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .mobile-links {
    display: flex;
    flex-direction: column;
    
    a {
      display: inline-block;
      padding: 10px 0;
      text-align: start;
      text-decoration: none;
      font-size: 1.1em;
      font-weight: 800;
    }
  }
`
