import styled from 'styled-components'
import media from '../../common/utils/media'
import { GridItem } from '../../common/utils/types'
import SectionDescription from '../atoms/SectionDescription'
import SectionTitle from '../atoms/SectionTitle'
import GridItemContainer from '../molecules/GridItemContainer'

type SectionProps = {
  title: string
  description: string
  gridItems: GridItem[]
}

const Section = ({ title, description, gridItems }: SectionProps) => (
  <StyledSection className="section">
    <SectionTitle title={title} />
    <SectionDescription description={description} />
    <GridItemContainer gridItems={gridItems} />
  </StyledSection>
)

export default Section

const StyledSection = styled.div`
  padding: 100px 14vw;

  h2 {
    margin-top: 0;
  }

  ${media.tablet`
    padding: 50px 100px;
  `}

  ${media.sp`
    padding: 50px;
  `}

  div {
    margin-top: 30px;
  }

  & > p {
    padding: 0 150px;

    ${media.tablet`
      padding: 0;
    `}
  }
`
