import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  ROUTE_ANTI_SOCIAL_PRINCIPLE_WITH_LANG,
  ROUTE_PRIVACY_POLICY_WITH_LANG,
  ROUTE_SITE_POLICY_WITH_LANG,
} from '../../common/utils/constants'
import media from '../../common/utils/media'
import BackButton from '../atoms/BackButton'
import AntiSocialPrinciple from '../atoms/AntiSocialPrinciple'
import PrivacyPolicy from '../atoms/PrivacyPolicy'
import LanguageToggle from '../molecules/LanguageToggle'
import { useState } from 'react'
import Header from '../organisms/Header'
import SitePolicy from '../atoms/SitePolicy'

type DisclaimerPageProps = {
  routePath: string
}

const DisclaimerPage = ({ routePath }: DisclaimerPageProps) => {
  // TODO: remove if else condition when there is eng text for site policy & privacy policy
  const [langs] = useState(
    ![ROUTE_SITE_POLICY_WITH_LANG, ROUTE_PRIVACY_POLICY_WITH_LANG].includes(
      routePath
    )
      ? ['en', 'ja']
      : ['ja']
  )
  const params = useParams() as any
  // TODO: remove if else condition when there is eng text for site policy & privacy policy
  const [selectedLang] = useState(
    ![ROUTE_SITE_POLICY_WITH_LANG, ROUTE_PRIVACY_POLICY_WITH_LANG].includes(
      routePath
    )
      ? params.lang
      : 'ja'
  )

  return (
    <>
      <Header />
      <StyledDisclaimerPage>
        <BackButton />
        <LanguageToggle
          routeWithLang={routePath}
          langs={langs}
          selectedLang={selectedLang}
        />
        {routePath === ROUTE_ANTI_SOCIAL_PRINCIPLE_WITH_LANG && (
          <AntiSocialPrinciple lang={selectedLang} />
        )}
        {routePath === ROUTE_PRIVACY_POLICY_WITH_LANG && (
          <PrivacyPolicy lang={selectedLang} />
        )}
        {routePath === ROUTE_SITE_POLICY_WITH_LANG && (
          <SitePolicy lang={selectedLang} />
        )}
      </StyledDisclaimerPage>
    </>
  )
}

export default DisclaimerPage

const StyledDisclaimerPage = styled.div`
  padding: 80px 14vw;
  text-align: left;

  ${media.tablet`
    padding: 50px 100px;
    width: auto;
  `}

  ${media.sp`
    padding: 50px;
  `}

  p {
    width: 50%;
    text-align: left;
    white-space: break-spaces;

    ${media.tablet`
      width: 100%;
    `}
  }

  ol {
    max-width: calc(50% - 15px);
    margin-top: 10px;
    padding-inline-start: 15px;
    counter-reset: item;

    ${media.tablet`
      max-width: 100%;
    `}

    & > li {
      margin-bottom: 10px;

      counter-increment: item;
      ::marker {
        content: counters(item, '.') '. ';
      }
    }

    li.bolded {
      font-weight: bold;
    }

    p {
      width: 100%;
      font-weight: normal;
    }

    text {
      font-weight: normal;
    }

    ol {
      max-width: calc(100% - 30px);
      padding-inline-start: 35px;
      margin-bottom: 15px;

      li {
        font-weight: normal;
      }
    }

    ul:not(.visible-list-item) {
      li {
        margin-bottom: 5px;
        font-weight: normal;

        counter-increment: none;

        ::marker {
          content: none;
        }
      }

      ${media.sp`
        padding-inline-start: 15px;
      `}
    }

    ul.visible-list-item {
      margin-left: 40px;
      padding: 0;
      list-style-type: circle;

      li {
        margin-bottom: 5px;
        font-weight: normal;
      }
    }
  }

  a {
    cursor: pointer;
    margin-bottom: 10px;
    text-decoration: underline;

    :hover {
      color: lightgrey;
    }
  }
`
