import styled from 'styled-components'
import {
  ROUTE_NEWS_RELEASE_ARTICLE_WITH_LANG,
  ROUTE_NEWS_RELEASE_WITH_LANG,
} from '../../common/utils/constants'
import media from '../../common/utils/media'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'
import LanguageToggle from '../molecules/LanguageToggle'
import ICO_EXTERNAL_LINK from '../../assets/images/icon-external-link.svg'

interface NewsRelease {
  title: string
  source: string
  date: string
  url?: string
}

const NewsReleasePage = () => {
  const params = useParams() as any
  const history = useHistory()

  const goToNewsReleasePage = (NewsRelease: NewsRelease) => {
    const source = NewsRelease.source

    // If URL is provided in the JSON object,
    // redirect to the URL instead of moving to Article page
    if (NewsRelease.url) {
      const w = window.open(NewsRelease.url, '_blank')
      if (w) {
        w.focus()
      }
    } else {
      history.push(
        ROUTE_NEWS_RELEASE_ARTICLE_WITH_LANG.replace(
          ':article',
          source
        ).replace(':lang', selectedLang)
      )
    }
  }

  const [NewsReleases, setNewsReleases] = useState([] as NewsRelease[])
  const [loading, setLoading] = useState(true)

  const [selectedLang, setSelectedLang] = useState(params.lang || '')
  const [langs, setLangs] = useState([]) as any

  const getData = () => {
    // prevent browser from caching the file by appending random parameter.
    // source: https://stackoverflow.com/a/15041641
    fetch(
      `../../news-releases/news-releases.json?nocache=${new Date().getTime()}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    )
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        const allLangs = Object.keys(json['news-releases'])
        let tmpSelectedLang = selectedLang
        if (!tmpSelectedLang || !allLangs.includes(tmpSelectedLang)) {
          tmpSelectedLang = allLangs[0]
          setSelectedLang(tmpSelectedLang)
        }
        setLangs(allLangs)
        setNewsReleases(json['news-releases'][tmpSelectedLang])
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header />
      <StyledNewsRelease>
        {!loading && (
          <>
            <div className="subtitle-container">
              <h2>News Release</h2>
            </div>
            <LanguageToggle
              routeWithLang={ROUTE_NEWS_RELEASE_WITH_LANG}
              langs={langs}
              selectedLang={selectedLang}
            />
            <br />
            <div className="news-releases">
              {NewsReleases && NewsReleases.length > 0 ? (
                NewsReleases.map((NewsRelease: any) => (
                  <li
                    key={NewsRelease.title}
                    onClick={() => {
                      goToNewsReleasePage(NewsRelease)
                    }}
                  >
                    <span>
                      {NewsRelease.date.split(' ')[0]}
                      {': '}
                      {NewsRelease.title}
                    </span>
                    {NewsRelease.url && (
                      <img src={ICO_EXTERNAL_LINK} alt="External Link" />
                    )}
                  </li>
                ))
              ) : (
                <p>Please come back later.</p>
              )}
            </div>
          </>
        )}
      </StyledNewsRelease>
      <Footer />
    </>
  )
}

export default NewsReleasePage

export const StyledNewsRelease = styled.div`
  padding: 80px 14vw;
  text-align: left;
  width: 50%;

  ${media.tablet`
      padding: 50px 100px;
      width: auto;
  `}

  ${media.sp`
    padding: 50px;
  `}

  div.subtitle-container {
    width: fit-content;
  }
  h2 {
    width: 100%;
    text-align: left;
    font-family: montserrat;

    ${media.sp`
      margin: 0;
    `}
  }

  div.news-releases {
    & {
      margin-bottom: 10px;
      width: fit-content;
      color: white;

      li:hover {
        text-decoration: underline;
      }

      li {
        padding: 5px;
      }

      span {
        cursor: pointer;
      }

      img {
        vertical-align: middle;
        padding-left: 3px;
        width: 20px;
        cursor: pointer;
      }
    }
  }
`
