import styled from 'styled-components'
import { ABOUT_US_EN_DESCRIPTION, ABOUT_US_JA_DESCRIPTION, ROUTE_ABOUT_US_WITH_LANG } from '../../common/utils/constants'
import media from '../../common/utils/media'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import LanguageToggle from '../molecules/LanguageToggle'
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'

const AboutUsPage = () => {
  const params = useParams() as any

  const [selectedLang, setSelectedLang] = useState(params.lang || '')
  const [corporateInfo, setCorporateInfo] = useState(null) as any
  const [langs, setLangs] = useState(null) as any

  // convert 2 lists into tuple-like arrays; for example, zip([1, 2, 3], ['a', 'b', 'c']) gives [[1, 'a'], [2, 'b'], [3, 'c']]
  const zip = (a: [], b: []) => a.map((k, i) => [k, b[i]])

  const getData = () => {
    // prevent browser from caching the file by appending random parameter.
    // source: https://stackoverflow.com/a/15041641
    fetch(`../../info/corporate-info.json?nocache=${(new Date()).getTime()}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        const allLangs = Object.keys(json)
        let tmpSelectedLang = selectedLang
        if (!tmpSelectedLang || !allLangs.includes(tmpSelectedLang)) {
          tmpSelectedLang = allLangs[0]
          setSelectedLang(tmpSelectedLang)
        }
        setLangs(allLangs)

        const tmpCorporateInfo = json[tmpSelectedLang]
        const corporateInfo = zip(
          tmpCorporateInfo.titles,
          tmpCorporateInfo.values
        )
        setCorporateInfo(corporateInfo)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
    // eslint-disable-next-line
  }, [])

  return corporateInfo ? (
    <>
      <Header />
      <StyledAboutUs>
        <div className="subtitle-container">
          <h2>Corporate Info</h2>
        </div>
        <LanguageToggle
          routeWithLang={ROUTE_ABOUT_US_WITH_LANG}
          langs={langs}
          selectedLang={selectedLang}
        />
        <p>
          {selectedLang === 'en' ? ABOUT_US_EN_DESCRIPTION : ABOUT_US_JA_DESCRIPTION}
        </p>
        <table>
          <tbody>
            {corporateInfo.map((titleValuePair: string[]) => (
              <tr key={`${titleValuePair[0]}-${titleValuePair[1]}`}>
                <th>{titleValuePair[0]}</th>
                <td>{titleValuePair[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </StyledAboutUs>
      <Footer />
    </>
  ) : (
    <></>
  )
}

export default AboutUsPage

export const StyledAboutUs = styled.div`
  padding: 80px 14vw;
  text-align: left;
  width: 50%;

  ${media.tablet`
    padding: 50px 100px;
    width: auto;
  `}

  ${media.sp`
    padding: 50px;
  `}

  table {
    margin-top: 20px;
    border-spacing: 10px 0;

    ${media.sp`
      border-spacing: 0;
    `}

    tr {
      vertical-align: top;
      line-height: 30px;

      ${media.sp`
        display: flex;
        flex-wrap: wrap;
        column-gap: 17px;
      `}
    }

    th {
      min-width: 12vw;

      ${media.tablet`
        min-width: 90px;
      `}
    }

    th,td {
      white-space: pre-line;
      padding: 0;
    }
  }

  div.subtitle-container {
    width: fit-content;
  }

  h2 {
    width: 100%;
    text-align: left;
    font-family: montserrat;
    overflow: hidden;

    ${media.sp`
      margin: 0;
    `}
  }
`
