import styled from 'styled-components'
import LOGO_FOLIO from '../../assets/images/logo-folio.svg'
import LOGO_FOLIO_HOLDINGS from '../../assets/images/logo-folio-holdings.svg'
import LOGO_ALPACA from '../../assets/images/logo-alpaca.svg'
import media from '../../common/utils/media'
import { URL_FOLIO_HD, URL_FOLIO_SEC } from '../../common/utils/constants'

const CorporateLinks = () => {
  return (
    <StyledCorporateLinks>
      <a href="/">
        <img src={LOGO_ALPACA} alt="AlpacaTech"/>
      </a>
      <div className="group-companies-container">
        <span className="group-companies-heading">グループ会社</span>
        <div className="group-companies">
          <a href={URL_FOLIO_HD} target="_blank" rel="noopener noreferrer">
            <img src={LOGO_FOLIO_HOLDINGS} alt="Folio-holdings" />
          </a>
          |
          <a href={URL_FOLIO_SEC} target="_blank" rel="noopener noreferrer">
            <img src={LOGO_FOLIO} alt="Folio-sec" />
          </a>
        </div>
      </div>
    </StyledCorporateLinks>
  )
}

export default CorporateLinks

const StyledCorporateLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  ${media.tablet`
    align-items: center;
  `}

  div.group-companies-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${media.tablet`
      align-items: center;
    `}
  }

  span.group-companies-heading {
    font-size: 13px;
    font-weight: bold;
    color: #999;
  }

  div.group-companies {
    display: flex;
    align-items: center;
    gap: 24px;

    ${media.tablet`
      gap: 16px;
    `}
  }
`
