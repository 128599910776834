import { useState } from 'react'
import styled from 'styled-components'
import media from '../../common/utils/media'
import ICO_MENU from '../../assets/images/icon-menu.svg'
import { ROUTE_ABOUT_US, ROUTE_HIRING, ROUTE_NEWS_RELEASE, ROUTE_CONTACT_US } from '../../common/utils/constants'
import MobilePopup from '../molecules/MobilePopup'

const Header = () => {
  const [isMobilePopupShown, setMobilePopupShown] = useState(false)

  const showMobilePopup = () => {
    setMobilePopupShown(true)
    document.querySelector('html')?.classList.add('-no-scroll')
  }
  const hideMobilePopup = () => {
    setMobilePopupShown(false)
    document.querySelector('html')?.classList.remove('-no-scroll')
  }

  return (
  <StyledHeader>
    <MobilePopup
      isMobilePopupShown={isMobilePopupShown}
      onCloseBtnClicked={hideMobilePopup}
    />
    <div className="top-bar">
      <a href="/" className="title">
        AlpacaTech
      </a>
      <span className="links">
        <a
          href={ROUTE_ABOUT_US}
          className={
            window.location.pathname.includes(ROUTE_ABOUT_US) ? 'active' : ''
          }
        >
          About Us
        </a>
        <a
          href={ROUTE_NEWS_RELEASE}
          className={
            window.location.pathname.includes(ROUTE_NEWS_RELEASE) ? 'active' : ''
          }
        >
          News Release
        </a>
        <a
          href={ROUTE_HIRING}
          className={
            window.location.pathname.includes(ROUTE_HIRING) ? 'active' : ''
          }
        >
          Jobs
        </a>
        <a
          href={ROUTE_CONTACT_US}
          className={
            window.location.pathname.includes(ROUTE_CONTACT_US) ? 'active' : ''
          }
        >
          Contact Us
        </a>
      </span>
      <div className="btn-mobile-nav" onClick={showMobilePopup}>
        <img
          src={ICO_MENU}
          alt="show-mobile-nav"
          title="show-mobile-nav"
        />
      </div>
    </div>
  </StyledHeader>
)}

export default Header

const StyledHeader = styled.header`
  & {
    padding: 3vh 14vw;

    .btn-mobile-nav {
      display: none;
      pointer-events: none;
    }

    ${media.tablet`
      padding: 3vh 100px;
      
      span.links {
        display: none;
      }
      .btn-mobile-nav {
        display: block;
        pointer-events: auto;
        cursor: pointer;
      }
    `}

    ${media.sp`
      padding: 30px 50px;
    `}

    div.top-bar {
      display: flex;
      align-items: center;

      a {
        white-space: pre;
      }

      a.title {
        text-decoration: none;
        color: #ff3333;
        font-size: 1.5em;
        font-weight: 800;
      }

      a:not(.title) {
        text-decoration: none;
        font-size: 1.1em;
        font-weight: 800;
        margin-left: 50px;
      }

      ${media.tablet`
        justify-content: space-between;
      `}

      a:not(.title):hover {
        color: #e3e3e3;
      }
    }
  }
`
